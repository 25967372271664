*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  overflow-y: scroll;
}


#root{
  width: 100%;
}


.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.noMinHeight{
  min-height: auto !important;
}

@font-face{
  font-family: "Kharkiv Tone";
  src: url("./assets/fonts/Kharkiv_Tone.ttf")
}
@font-face{
  font-family: "Klapt Bold";
  src: url("./assets/fonts/Klapt-Bold.ttf")
}
@font-face{
  font-family: "Atyp Display";
  src: url("./assets/fonts/AtypDisplay-Medium.ttf")
}